<template>
  <b-card-code>
    <!-- <p>{{organization}}organisation</p> -->
    <b-button
      v-if="this.$store.state.app.user.is_staff"
      type="button"
      variant="success"
      class="btn ml-auto m-1"
      size="sm"
    >
      <router-link
        style="color: #ffff !important"
        :to="'/report/add-scheduled-report'"
        >+ Add New Schedule</router-link
      >
    </b-button>
    <b-card-code class="bg-light-secondary mb-1" title="Search" v-if="is_staff">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%">
          <!-- <v-select
            class="ml-1"
            v-if="is_staff"
            v-model="organization"
            label="text"
            placeholder="--Select Organization--"
            :options="organizations"
            @input="searchFn"
            autocomplete
            style="width: 30%"
            :reduce="organization => organization.value"
          /> -->

          <!-- <b-form-input
            class="ml-1"
            id="name"
            v-model="name_filter"
            style="width: 50%"
            name="name"
            placeholder="Search with name"
          /> -->
          <b-form-select
            class="ml-1"
            v-if="is_staff"
            @change="searchFn"
            v-model="reportType"
            style="width: 30%"
            :options="reportTypeOption"
          >
          </b-form-select>

          <b-button
            style="width: 12%"
            class="ml-1"
            variant="primary"
            @click="searchFn"
          >
            Search
          </b-button>
          <!-- <button
            @click="generateReport()"
            type="button"
            class="btn ml-auto btn-info"
            v-if="
              !this.$store.state.app.user.is_staff &&
              this.$store.state.app.user.permissions.includes(
                'report.generate_report'
              )
            "
          >
            Generate Report
          </button> -->
        </div>
      </b-form>
    </b-card-code>

    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        id="orgTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        v-if="items.length"
        hover
        style="min-height: 150px"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(name)="data">
          {{ data.item.report_name.replace(/\.[^/.]+$/, "") }}
        </template>
        <template #cell(template_name)="data">
          <b class="text-success">
            {{ data.item.template_name }}
          </b>
        </template>
        <template #cell(actions)="data">
          <!-- <button @click="editOrg(data.item.org_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteOrg(data.item.org_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
          <b-dropdown
            size="sm"
            class="ml-1"
            variant="outline-primary"
            text="Actions"
          >
            <!-- <b-dropdown-item
            @click="downloadReport(data.item.schedule_id, data.item.report_name)"
            >Download Report</b-dropdown-item
          > -->
            <!-- <b-dropdown-item @click="allocateScanners(data.item.org_id)">Default Scanners</b-dropdown-item> -->
            <b-dropdown-divider />
            <b-dropdown-item
              @click="
                gotoDelete(
                  data.item.schedule_id,
                  data.item.report_name.replace(/\.[^/.]+$/, '')
                )
              "
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>

    <b-pagination
      class="mt-1"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    />
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ reportName }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteReport()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BBadge } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        // { key: "report_id", label: "#" },
        { key: "index", label: "#" },
        { key: "name", label: "Name" },
        { key: "template_name", label: "Template" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organization: this.$store.state.app.org_id,
      organizations: [],
      reportType: null,
      reportTypeOption: [
        { value: null, text: "--Select Scope--" },
        { value: 0, text: "Organization" },
        { value: 1, text: "Asset Group" },
        { value: 2, text: "Asset" },
      ],
      name_filter: "",
      loading: false,
      id: 0,
      reportName: "",
      report_id: "",
      openDeleteModal: false,
    };
  },
  created: function () {
    if (this.organization != null) {
      this.load();
      // this.loadOrg();
      this.searchFn();
    }
  },
  methods: {
    // loadOrg: function () {
    //   if (this.is_staff) {
    //     this.loading = true;

    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {

    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].org_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //       this.loading = false;
    //     });
    //   } else {
    //     this.organization = this.$store.state.app.user.profile.organization;
    //     this.searchFn();
    //   }
    // },
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/schedule/?org_id=" +
          this.organization,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.results.length;
        //   this.currentPage = 1
        //   this.perPage = 10
        //   this.rows = res.data.count
      });
    },
    searchFn: function (reset = true) {
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url = process.env.VUE_APP_BASEURL + "report/schedule?query=true";

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      // if (this.name_filter != null && this.name_filter != "") {
      //   this.filter.push({ name: this.name_filter });
      //   url = url + "&name=" + this.name_filter;
      // }

      if (this.reportType != null) {
        this.filter.push({ reportType: this.reportType });
        url = url + "&type=" + this.reportType;
      }

      console.log(this.filter);
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.loading = false;
        //   this.currentPage = 1
        //   this.perPage = 10
        //   this.rows = res.data.count
      });
    },
    deleteReport() {
      // this.reportName = report_name;
      // if (confirm("Do you really want to delete " + this.reportName +"?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/schedule/" +
          this.report_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
    gotoDelete(report_id, report_name) {
      this.openDeleteModal = true;
      this.report_id = report_id;
      this.reportName = report_name;
    },
    closeModal() {
      this.$refs["report_generator"].hide();
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
